import React, { useCallback } from 'react';
import {
  PageBackground,
  Container,
  ContentContainer,
  NavBar,
  LogoImg,
  Logo,
  Nav,
  NavItem,
  SignInButton,
  ScreenProtector,
} from "../Styling/NavBar-styling";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from "react";
import { ax, api_base_url } from "../Utils";
import { Feedback, FeedbackType, Message, User } from '../Types';
import FeedbackList from './Sections/FeedbackList';
import FeedbackCopilot from './Sections/FeedbackCopilot';
import FeedbackChat from './Sections/FeedbackChat';
import { UserProps } from '../UserPage/UserPage';
import { debounce, set, update } from 'lodash';
import { Id, toast } from 'react-toastify';
import { Editor } from '@tiptap/react';
import IconNavigation from '../Components/IconNavigation';
import HamburgerMenu from '../Components/HamburgerMenu';


const logoLink = "https://pngimg.com/uploads/letter_c/letter_c_PNG97.png";


type FeedbackProps = UserProps & {

};

const FeedbackPage = (props: FeedbackProps) => {

  const timeUnits = [
    { singular: 'år', unit: 'år', value: 1000 * 60 * 60 * 24 * 365 },
    { singular: 'månad', unit: 'månader', value: 1000 * 60 * 60 * 24 * 30 },
    { singular: 'dag', unit: 'dagar', value: 1000 * 60 * 60 * 24 },
    { singular: 'timme', unit: 'timmar', value: 1000 * 60 * 60 },
    { singular: 'minut', unit: 'minuter', value: 1000 * 60 },
    { singular: 'sekund', unit: 'sekunder', value: 1000 }
  ];
  const [copilotChat, setCopilotChat] = useState<string[]>(['']);
  const [inputText, setInputText] = useState('');
  const [answerLoading, setAnswerLoading] = useState<boolean[]>([]);
  const conversationRef = useRef<HTMLDivElement>(null);
  const [copilotChatId, setCopilotChatId] = useState<number | null>(null);
  const copilotEventSourceRef = useRef<EventSource | null>(null);

  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [selectedFeedback, setSelectedFeedback] = useState<Feedback | null>(null);
  const [lastClickedId, setLastClickedId] = useState<number | null>(null);
  const [feedbackChat, setFeedbackChat] = useState<Message[]>([]);
  const [feedbackType, setFeedbackType] = useState<FeedbackType>(null);

  const [draft, setDraft] = useState<Message | null>(null);
  const [content, setContent] = useState<string>('');
  const [promptContent, setPromptContent] = useState<string>('');
  const toastId = useRef<Id | null>(null);
  const editorRef = useRef<Editor | null>(null);


  const feedbacksLengthRef = useRef(feedbacks.length);
  const selectedFeedbackRef = useRef(selectedFeedback);
  const isFetchingList = useRef(false);
  const isFetchingUser = useRef(false);


  const navigate = useNavigate();
  
  useEffect(() => {
    if (!props.currentUser && !isFetchingUser.current) {
      isFetchingUser.current = true;
      ax.get('/users/company/whoami')
        .then((response) => props.setCurrentUser(response.data));
    }
    if (feedbacks.length === 0 && !isFetchingList.current){
      isFetchingList.current = true;
      ax.get('/feedback/all')
        .then((response) => {
          setFeedbacks(response.data)
          feedbacksLengthRef.current = response.data.length
        }
      );
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [copilotChat]);

  useEffect(() => {
    if (inputText === '') {
      const textarea = document.getElementById('feedbackCopilotInputId');
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = (textarea.scrollHeight - 30 > 100 ? 100 : textarea.scrollHeight - 30) + 'px';
      }
    }
  }, [inputText]);

  useEffect(() => {
    if (selectedFeedback && (selectedFeedback.id === selectedFeedbackRef.current?.id || feedbacks.length !== feedbacksLengthRef.current)) {
      feedbacksLengthRef.current = feedbacks.length;
      setLastClickedId(selectedFeedback.id);
    }
    else {
      setCopilotChatId(null);
      if (selectedFeedback) {
        setLastClickedId(selectedFeedback.id);
        updatePage(selectedFeedback.id);
        setFeedbackType(selectedFeedback.source_type);
      }
      else {
        setLastClickedId(null);
        blankPage();
      }
    }
    selectedFeedbackRef.current = selectedFeedback;
  }, [selectedFeedback]);

  useEffect(() => {
    return () => {
      if (copilotEventSourceRef.current) {
        copilotEventSourceRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    setPromptContent(content);
  }, [content]);



  const updatePage = async (id: number) => { 
    await ax.get(`/feedback/chat?feedback_id=${id}`)
      .then((response) => {
        setFeedbackChat(response.data)
        const unsentItem = response.data.find((item: Message) => item.sent_at === null);
        if (unsentItem) {
          setDraft(unsentItem);
          setContent(unsentItem.raw_body);
        }
        else {
          setDraft(null);
          setContent('');
        }
      });
    setCopilotChat(['']);
    streamWelcomeMessage();
  };

  const blankPage = () => {
    setDraft(null);
    setContent('');
    setFeedbackChat([]);
    setCopilotChat(['Hej! Välkommen till din inbox. Här kan du se och svara på dina meddelanden.']);
  };

  const handleHome = async () => {
    navigate('/user');
  };
  const handleSettings = async () => {
    //settings
  };
  const handleLanguages = async () => {
    //Show menu of possible languages to choose from
  };
  const handleSignOut = async () => {
    await ax.post('/auth/logout');
    window.location.href = 'https://ragge.ai/';
  };

  const handleSend = () => {
    if (inputText === ''){
      return;
    }
    setCopilotChat([...copilotChat, inputText, '']);
    //setSentInputText(inputText);
    //setInputText('');
    generateAnswer();
  };

  const handleEnterSend = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleTextareaChange = (e: { target: { value: React.SetStateAction<string>; style: { height: string; }; scrollHeight: number; }; }) => {
    setInputText(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight - 30 > 100 ? 100 : e.target.scrollHeight - 30) + 'px';
  };

  const scrollToBottom = () => {
    if (conversationRef.current && copilotChat.length !== 0) {
      const lastMessage = conversationRef.current.lastChild as HTMLElement;
      lastMessage.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const streamWelcomeMessage = async() => {
    if (selectedFeedback) {
      setAnswerLoading([true]);

      if (copilotEventSourceRef.current) {
        copilotEventSourceRef.current.close();
      }

      copilotEventSourceRef.current = new EventSource(
        `${api_base_url}/copilot/get_welcome_message?module=feedback&module_ref_id=${selectedFeedback.id}`, 
        {withCredentials: true}
      );

      copilotEventSourceRef.current.onmessage = function(event) {
        const data = JSON.parse(event.data);

        if (data.chat_id) {
          setCopilotChatId(data.chat_id);
        }
        else if (data.text) {
          setCopilotChat((prevChat) => {
            const lastChatItem = prevChat[0];
            const updatedLastChatItem = lastChatItem ? lastChatItem + data.text : data.text;
            return [...prevChat.slice(0, -1), updatedLastChatItem];
          });
        }
        /*else {
          eventSource.close();
        }*/
      };

      copilotEventSourceRef.current.onerror = (event) => {
        // console.error("EventSource failed:", event);
        copilotEventSourceRef.current?.close();
        setAnswerLoading([false]);
      };
    }
  };

  const generateAnswer = async () => {

    if (copilotEventSourceRef.current) {
      copilotEventSourceRef.current.close();
    }
    //console.log('feed id: ', selectedFeedback?.id, 'copchatid: ', copilotChatId, 'inputtext: ', inputText, 'promptcontent: ', promptContent);
    const requestBody: any = {
      text: inputText,
      module: 'feedback',
      current_content: promptContent,
      chat_id: copilotChatId,
      module_ref_id: selectedFeedback?.id,
    };

    setAnswerLoading([...answerLoading, true]);

    const getAnswerId = await ax.post('/copilot/request_params', requestBody);

    copilotEventSourceRef.current = new EventSource(
      `${api_base_url}/copilot/get_answer?query_id=${getAnswerId.data}`, 
      {withCredentials: true}
    );

    setInputText('');
    setContent('');

    copilotEventSourceRef.current.onmessage = function(event) {
      const data = JSON.parse(event.data);

      if (data.copilot_text) {
        setCopilotChat((prevChat) => {
          const lastChatItem = prevChat[prevChat.length - 1];
          const updatedLastChatItem = lastChatItem ? lastChatItem + data.copilot_text : data.copilot_text;
          return [...prevChat.slice(0, -1), updatedLastChatItem];
        });
      }
      else if (data.content_text) {
        setContent((prevContent) => {
          const updatedContent = prevContent + data.content_text;
          return updatedContent;
        })

      }
  };

    copilotEventSourceRef.current.onerror = (event) => {
      // console.error("EventSource failed:", event);
      copilotEventSourceRef.current?.close();
      setAnswerLoading((prevAnswerLoading) => {
        //Create a new array with all elements except the last one
        const newArray = prevAnswerLoading.slice(0, -1);
        //Add false as the new last element
        newArray.push(false);
        return newArray;
      });
      if (editorRef.current) {
        handleChange(editorRef.current.getHTML(), editorRef.current.getText());//flytta editor till FeedbackPage
      }
    };
  };

  function timePassed(date: Date): string {
    const now = new Date();
    const difference = now.getTime() - date.getTime();
    let { singular, unit, value } = timeUnits.find(({value}) => difference >= value) || timeUnits[timeUnits.length - 1];
    if (Math.floor(difference / value) === 1) return `${Math.floor(difference / value)} ${singular}`;
    return `${Math.floor(difference / value)} ${unit}`;
  }

  // Debounced function that shows the notification
  const handleChange = useCallback(debounce((html: string, text: string) => {
    if (selectedFeedback) {
      toastId.current = toast.info('Saving draft...');
      if (draft) {
        const requestBody: any = {
          id: draft.id,
          feedback_id: draft.feedback_id,
          raw_body: html,
          text: text,
          role: 'user',
        }
        ax.patch(`/feedback/chat/message`, requestBody)
          .then((response) => {
            toast.update(toastId?.current ?? '', {type: 'success', render: 'Draft saved', autoClose: 2000 });
            setDraft(response.data);
            console.log('draft saved: ', response.data.id);
          });
      }

      else {
        const requestBody: any = {
          feedback_id: selectedFeedback.id,
          raw_body: html,
          text: text,
          role: 'user',
        }
        ax.patch(`/feedback/chat/message`, requestBody)
          .then((response) => {
            toast.update(toastId?.current ?? '', {type: 'success', render: 'Draft saved', autoClose: 2000 });
            setDraft(response.data);
            console.log('draft saved: ', response.data.id);
          });
      }
    }
  }, 3000), [selectedFeedback, draft]); // 3 seconds

  const sendEmail = async (html: string) => {
    //console.log('send email: ', html, selectedFeedback, draft);
    if (html && selectedFeedback && draft) {
      const requestBody = {
        feedback_id: selectedFeedback.id,
        feedback_message_id: draft.id,
        body: html,
      };
      ax.post(`/feedback/email/send`, requestBody)
        .then(async (response) => {
          toast.success('Email sent!');
          setDraft(null);
          setContent('');
          await ax.get(`/feedback/chat?feedback_id=${selectedFeedback.id}`)
            .then((response2) => {
              //console.log('response2: ', response2.data);
              setFeedbackChat(response2.data)
            });
          
        });
    }
    else {
      toast.error('Error: Could not send email.');
    }
  };

  const updatePromptContent = (html: string) => {
    setPromptContent(html);
  };

  function formatDateTime(dateTimeString: string): string {
    const dateTime = new Date(dateTimeString);
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
    const oneYear = 365 * 24 * 60 * 60 * 1000; // milliseconds in one year
    const difference = now.getTime() - dateTime.getTime();

    // Format options for displaying the date and time
    const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
    const dateOptions: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
    const yearOptions: Intl.DateTimeFormatOptions = { year: 'numeric' };

    if (difference < oneDay) {
        // Less than a day ago, show time
        return dateTime.toLocaleTimeString('sv-SE', timeOptions);
    } else if (difference < oneYear) {
        // More than a day but less than a year ago, show date
        return dateTime.toLocaleDateString('sv-SE', dateOptions);
    } else {
        // More than a year ago, show year
        return dateTime.toLocaleDateString('sv-SE', yearOptions);
    }
  };

  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <LogoImg onClick={handleHome} src={logoLink} alt="Image"/>
            <Logo>ragge.ai</Logo>
            <HamburgerMenu />
          </NavBar>
          <ContentContainer>
            <IconNavigation />
            <FeedbackList
              feedbacks={feedbacks}
              setSelectedFeedback={setSelectedFeedback}
              lastClickedId={lastClickedId}
              formatDateTime={formatDateTime}
            />
            <FeedbackChat 
              feedbackChat={feedbackChat}
              feedbackType={feedbackType}
              timePassed={timePassed}
              selectedFeedback={selectedFeedback}
              handleChange={handleChange}
              cancelHandleChange={handleChange.cancel}
              content={content}
              selectedFeedbackRef={selectedFeedbackRef}
              sendEmail={sendEmail}
              updatePromptContent={updatePromptContent}
              editorRef={editorRef}
            />
            <FeedbackCopilot
              currentUser={props.currentUser}
              inputText={inputText}
              handleTextareaChange={handleTextareaChange}
              handleSend={handleSend}
              handleEnterSend={handleEnterSend}
              conversationRef={conversationRef}
              copilotChat={copilotChat}
              answerLoading={answerLoading}
            />
          </ContentContainer>
        </Container>
      </ScreenProtector>
    </PageBackground>
  );
};
export default FeedbackPage;
