import React from 'react';
import {
  AdvancedOptionsSection,
  TemplatesSection,
  TemplatesTitle,
  CloseButton,
  RelevantTemplateStyling,
  RelevantTemplateText,
  RelevantTemplateTitle,
  Checkbox,
  OptionTitleSection,
  AddMessageButton,
  GenerateResponseContainer,
  MessageTitle,
  AdvancedTextInput,
  LengthSlider,
  LabelsContainer,
} from "../../ErrandsPage/ErrandsPage-styling"
import { FAQ, HistResponse, Template, User } from '../../ErrandsPage/Types';
import { 
  AISection,
  AssistantHeader,
  AssistantSection, 
  ConversationSection, 
  CopilotChatItem, 
  ChatProfileImgContainer, 
  CopilotProfileImg, 
  CopilotChatItemText, 
  CopilotInputSection, 
  SendIcon, 
  CopilotInput, 
} from '../../MarketingPage/MarketingPage-styling';
import { BlinkingSpan, CopilotImgAnimation } from '../../UserPage/UserPage-styling';
import MarkdownRenderer from '../../Components/MarkdowRenderer';


const sendMessageLink = "https://i.postimg.cc/XY0PqNg8/send-icon-2048x1863-u8j8xnb6.png";
//const companyImageLink = 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/ICA_logo.svg/1200px-ICA_logo.svg.png';
const companyImageLink = null;

// type Props = {
//   templates: Template[];
//   histResponses: HistResponse[];
//   toggleAdvancedOptions: () => void;
//   handleTemplateCheck: (index: number, checked: boolean) => void;
//   handleHistResponsesCheck: (index: number, checked: boolean) => void;
//   generateResponse: () => void;
//   faqs: FAQ[];
//   handleFaqCheck: (index: number, checked: boolean) => void;
//   advancedOptionsText: string;
//   setAdvancedOptionsText: (s: string) => any;
//   answerLength: number;
//   handleLengthChange: (e: any) => void;
// };

type Props = {
  currentUser: User | null;
  inputText: string;
  handleTextareaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleEnterSend: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  conversationRef: React.RefObject<HTMLDivElement>;
  handleSend: () => void;
  copilotChat: string[];
  answerLoading: boolean[];
};

const FeedbackCopilot = (props: Props) => {
  return (
    <AssistantSection>
      <AssistantHeader>AI-Medhjälparen</AssistantHeader>
      <AISection>
        <ConversationSection ref={props.conversationRef} >
          {props.copilotChat.map((item, index)=> {
            if (index % 2 === 0) { // Check if index is even
              return (
                <CopilotChatItem key={index} $isCopilot={true} >
                  {props.answerLoading[index/2] &&
                    <CopilotImgAnimation />
                  }
                <ChatProfileImgContainer $isCopilot={true} >
                  {companyImageLink ? (
                    <CopilotProfileImg src={companyImageLink} alt="AI"/>
                  ) : (
                    "AI"
                  )}
                </ChatProfileImgContainer>
                {props.answerLoading[index/2] &&
                  <MarkdownRenderer markdownText={item} isStreaming={true} fontSize={16} />
                }
                {!props.answerLoading[index/2] &&
                  <MarkdownRenderer markdownText={item} isStreaming={false} fontSize={16} />
                }
              </CopilotChatItem>
            )
          } else
            return (
              <CopilotChatItem key={index} $isCopilot={false} >
                <ChatProfileImgContainer $isCopilot={false} >
                  {props.currentUser?.first_name.charAt(0)}
                </ChatProfileImgContainer>
                <CopilotChatItemText>{item}</CopilotChatItemText>
              </CopilotChatItem>
            )
          })}
        </ConversationSection>
        <CopilotInputSection>
          <SendIcon src={sendMessageLink}
            onClick={props.handleSend}
          />
          <CopilotInput
            id='feedbackCopilotInputId'
            value={props.inputText}
            onChange={props.handleTextareaChange}
            placeholder="Skriv din fråga här..."
            rows={1}
            onKeyDown={props.handleEnterSend}
          />
        </CopilotInputSection>

      </AISection>
    </AssistantSection>
  );
};
export default FeedbackCopilot;